@import "../base/variables";
@import "../../../../../../../node_modules/bootstrap/scss/mixins/breakpoints";

/*------------------------------------*\
		Header
\*------------------------------------*/

header.header {
  position: fixed;
  height: 80px;
  width: 100%;
  padding: 0 15px;
  z-index: 1000;
  background: #fff;
  transition: all .7s;

  @include media-breakpoint-up(xl) {
    padding: 0 50px;
  }

  @include media-breakpoint-up(xxl) {
    height: 150px;
    padding: 0 25px;
  }

  &.white {
    background: #fff !important;
  }

  > .navbar {
    height: 100%;
    padding: 0;
  }

  #open-menu {
    display: block;
    color: #313131;
    font-size: 14px;
    font-weight: bold;
    letter-spacing: 0.8em;

    &:hover {
      text-decoration: none;
    }

    img {
      width: 31px;
      height: 31px;
      margin-right: 8px;

      &.white {
        display: none;
      }
    }

    @include media-breakpoint-down(sm) {
     span {
       display: none;
     }
    }
  }

  #logo-wmp-baseline {
    display: inline-block;
    text-align: center;
    text-decoration: none;
    transition: all .7s;

    @include media-breakpoint-down(md) {
      display: none;
    }

    img {
      height: 31px;
      margin-bottom: 15px;
      margin-left: auto;
      margin-right: auto;

      @include media-breakpoint-up(xxl) {
        height: 41px;
      }

      &.white {
        display: none;
      }
    }

    .baseline {
      margin: 0;
      text-align: center;
      text-transform: uppercase;
      font-size: 9px;
      letter-spacing: .6em;
      font-weight: bold;
      font-family: "League Spartan";
      color: #313131;
      opacity: 0.45;
    }
  }

  #lang-switcher {
    display: inline-block;
    margin: 0;
    padding: 0;
    text-align: right;
    list-style: none;

    li {
      display: inline-block;
      margin-left: 15px;
      text-transform: uppercase;

      @include media-breakpoint-down(sm) {
        margin-left: 5px;
      }

      a {
        font-size: 14px;
        letter-spacing: 0.8em;
        text-decoration: none;
        color: rgba(51,51,51,0.45);

        &:after {
          display: block;
          opacity: 0;
          width: 45px;
          height: 2px;
          margin-top: 6px;
          content: " ";
          background-image: url($svg-dir + 'line-lang.svg');
          background-size: contain;
          background-repeat: no-repeat;
        }

        &:hover, &:active, &:focus {
          color: #333;

          &:after {
            opacity: 1;
          }
        }
      }

      &.current-lang {
        a {
          color: #333;

          &:after {
            opacity: 1;
          }
        }
      }
    }
  }

  #contact-btn {
    margin-left: 20px;
    padding: 16px;

    @include media-breakpoint-down(sm) {
      margin-left: 5px;
    }

    img {
      width: 30px;
    }
  }

  &.sticky {
    height: 80px;

    #logo-wmp-baseline {
      img {
          height: 32px;
          margin-bottom: 8px;
      }
    }
  }

  .ml-auto, .mr-auto {
      width: 220px;

    @include media-breakpoint-down(md) {
      width: auto;
    }
  }
}

body.white {
  header.header {
    #open-menu {
      color: #fff;

      img {
        display: none;

        &.white {
          display: inline-block;
        }
      }
    }

    #logo-wmp-baseline {
      img {
        display: none;

        &.white {
          display: block;
        }
      }

      .baseline {
        color: #ffffff;
      }
    }

    #lang-switcher {
      li {
        a {
          color: rgba(255,255,255,0.45);


          &:hover, &:active, &:focus {
            color: #fff;
          }

          &:after {
            background-image: url(../../assets/svg/layout/line-lang-white.svg);
          }
        }

        &.current-lang {
          a {
            color: #fff;
          }
        }
      }
    }
  }
}

body.menu-white {
  header.header {
    #open-menu {
      color: #fff;

      img {
        display: none;

        &.white {
          display: inline-block;
        }
      }
    }
  }
}