@import "../base/variables";
@import "../../../../../../../node_modules/bootstrap/scss/mixins/breakpoints";

/*------------------------------------*\
		Footer
\*------------------------------------*/

footer#footer {
  position: relative;
  width: 100%;

  .top {
    position: absolute;
    top: 0;
    height: 30%;
    width: 100%;
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;

    @include media-breakpoint-down(lg) {
      height: 35%;
      background-position: bottom center;
    }
  }

  .bottom {
    position: absolute;
    height: 70%;
    top: 30%;
    width: 100%;
    background-color: #000000;
    background-image: url($svg-dir + 'pattern-footer.svg');
    background-size: cover;
    background-repeat: no-repeat;

    @include media-breakpoint-down(lg) {
      height: 65%;
      top: 35%;
    }
  }

  .container {
    padding-top: 80px;
    padding-bottom: 20px;
    text-align: center;
    z-index: 1;

    @include media-breakpoint-down(lg) {
      padding-top: 40px;
    }

    #newsletter {
      background: #fff;
      margin-bottom: 30px;
      padding: 30px 50px;
      text-align: left;

      @include media-breakpoint-down(lg) {
        padding: 15px;
        margin-bottom: 15px;
      }

      .title {
        font-size: 48px;
        font-weight: bold;
        font-family: "Playfair Display";
        letter-spacing: 0.01em;

        @include media-breakpoint-down(lg) {
          font-size: 32px;
        }

        @include media-breakpoint-down(md) {
          text-align: center;
        }
      }

      .subtitle, .text {
        font-size: 14px;
        line-height: 24px;
        letter-spacing: 0.01em;
        opacity: 0.54;

        @include media-breakpoint-down(sm) {
          display: none;
        }
      }

      .text {
        padding-bottom: 15px;
        padding-left: 25px;
        border-bottom: 1px solid rgba(0,0,0,0.65);
      }


      #newsletter-form {
        margin-top: 20px;

        .caldera-grid {
          margin-top: 10px;
          display: inline-block;
          vertical-align: bottom;
          @include calc(width, '100% - 220px');

          @include media-breakpoint-down(sm) {
            width: 100%;
          }

          .row {
            margin-bottom: 0 !important;
          }
        }

        .button {
          float: right;

          @include media-breakpoint-down(sm) {
            float: none;
            width: 100%;
            margin-top: -20px;
          }
        }
      }


      .follow-us {
        margin-top: 15px;
        padding-left: 25px;

        @include media-breakpoint-down(sm) {
          display: none;
        }

        span {
          display: inline-block;
          font-size: 30px;
          font-weight: bold;
          font-family: "Playfair Display";
          margin-right: 7px;
          vertical-align: bottom;

          @include media-breakpoint-down(xl) {
            font-size: 25px;
          }
        }

        .social-media {
          display: inline-block;
          background: $black;
          border-radius: 50%;
          width: 29px;
          height: 29px;
          font-size: 18px;
          color: #fff;
          letter-spacing: 0;
          text-align: center;
          vertical-align: middle;

          i {
            vertical-align: middle;
          }
        }
      }
    }

    #footer-logo {
      height: 98px;
      margin-bottom: 30px;

      @include media-breakpoint-down(sm) {
        height: 60px;
        margin-bottom: 15px;
      }
    }

    #footer-menu {
      margin-bottom: 2px;

      @include media-breakpoint-down(sm) {
        margin-bottom: 0px;
        font-size: 12px;
      }

      ul {
        display: inline-block;
        margin-left: auto;
        margin-right: auto;
        margin-bottom: 0;
        padding-left: 0;
        list-style: none;

        li {
          display: inline-block;

          a {
            color: rgba(255,255,255,0.59);
            text-decoration: none;
            font-weight: bold;

            &:hover, &:focus, &:active {
              color: #fff;
            }
          }

          &:not(:last-child):after {
            content: "•";
            display: inline-block;
            margin-right: 5px;
            color: rgba(255,255,255,0.59);
          }
        }
      }
    }

    #footer-professional-card {
      margin-bottom: 20px;
      font-size: 14px;
      line-height: 24px;
      color: rgba(255,255,255,0.35);

      @include media-breakpoint-down(sm) {
        margin-bottom: 10px;
        font-size: 12px;
        line-height: 20px;
      }

      a {
        text-decoration: underline;
        color: rgba(255,255,255,.49);

        &:hover, &:focus, &:active {
          color: #fff;
        }
      }
    }

    #footer-copyright {
      @include media-breakpoint-down(sm) {
        font-size: 12px;
        line-height: 20px;
      }

      ul {
        display: inline-block;
        margin-left: auto;
        margin-right: auto;
        margin-bottom: 0;
        padding-left: 0;
        list-style: none;

        li {
          display: inline-block;
          color: rgba(255,255,255,0.49);

          a {
            color: rgba(255,255,255,0.49);
            text-decoration: underline;

            &:hover, &:focus, &:active {
              color: #fff;
            }
          }

          &:not(:last-child):after {
            content: "-";
            display: inline-block;
            margin-right: 5px;
            color: rgba(255,255,255,0.59);
          }
        }
      }

      #logo-tiz {
        height: 23px;
        margin-left: 3px;
        opacity: 0.51;
      }
    }
  }
}