@import "../base/variables";
@import "../../../../../../../node_modules/bootstrap/scss/mixins/breakpoints";

/*------------------------------------*\
		Buttons
\*------------------------------------*/

.button {
  display: inline-block;
  padding: 18px 25px;
  font-weight: bold;
  font-family: "Playfair Display";
  font-size: 18px;
  text-align: center;
  @include transition;

  @include media-breakpoint-down(lg) {
    padding: 15px;
  }

  @include media-breakpoint-down(md) {
    padding: 14px 20px;
    font-size: 14px;
  }

  img.hover {
    display: none;
  }

  @include media-breakpoint-up(xl) {
    &:hover, &:focus, &:active {
      text-decoration: none;

      img {
        display: none;

        &.hover {
          display: inline-block;
        }
      }
    }
    padding: 15px;
  }
}

.btn-outline-white {
  border: 1px solid #fff;
  color: #fff;


  &:hover, &:focus, &:active, &.active {
    background: #fff;
    color: $black;
  }
}

.btn-outline-black {
  border: 1px solid $black;
  color: $black;

  &:hover, &:focus, &:active, &.active {
    background: $black;
    color: #fff;
  }
}

.btn-black {
  background: $black;
  color: #fff;
  border: 1px solid $black;

  &:hover, &:focus, &:active, &.active {
    color: $black;
    background: #fff;
  }
}

.btn-white {
  background: #eeefee;
  color: $black;
  border: 1px solid #eeefee;

  &:hover, &:focus, &:active, &.active {
    color: #fff;
    background: $black;
    border-color: $black;
  }
}