@import "../base/variables";
@import "../../../../../../../node_modules/bootstrap/scss/mixins/breakpoints";

/*------------------------------------*\
						Main menu
\*------------------------------------*/

#big-menu {
  display: flex;
  position: fixed;
  z-index: 10000;
  width: 100%;
  height: 100%;
  bottom: -100%;
  margin: 0;

  background-color: #080541; // Old browsers
  background: #080541; /* Old browsers */
  background: -moz-linear-gradient(44deg, rgb(8,5,65) 0%, rgb(41,10,89) 51%, rgb(14,42,93) 100%); /* FF3.6-15 */
  background: -webkit-linear-gradient(44deg, rgb(8,5,65) 0%, rgb(41,10,89) 51%, rgb(14,42,93) 100%); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(44deg, rgb(8,5,65) 0%, rgb(41,10,89) 51%, rgb(14,42,93) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#080541', endColorstr='#0e2a5d',GradientType=1 ); /* IE6-9 fallback on horizontal gradient */

  justify-content: center;
  flex-direction: column;
  text-align: center;
  transition: bottom .7s;

  &.active {
    bottom: 0;
  }

  ul {
    margin: 0;
    padding: 0;
    transition: none;
    z-index: 1;

    li {
      color: #fff;
      font-family: "Playfair Display";
      font-weight: 900;
      font-size: 30px;
      list-style: none;
      margin-bottom: 25px;

      @include media-breakpoint-up(xxl) {
        margin-bottom: 45px;
      }

      @include media-breakpoint-down(md) {
        font-size: 24px;
      }

      @include media-breakpoint-down(sm) {
        margin-bottom: 20px;
        font-size: 20px;
      }

      &:last-child {
        margin-bottom: 0;
      }

      a {
        position: relative;
        color: rgba(255, 255, 255, 0.79);
        letter-spacing: 0.2em;

        @media (min-width: 1025px) {
          text-decoration: none;

          &:not(.social-media) {
            &:before, &:after {
              content: " ";
              position: absolute;
              height: 2px;
              width: 0;
              bottom: -2px;
              background: #fff;
              transition: all .5s;
            }

            &:before {
              left: 50%;
            }

            &:after {
              right: 50%;
            }

            &:hover, &:active, &:focus {
              color: #fff;

              &:before, &:after {
                width: 50%;
              }

              &:before {
                left: 1px;
              }

              &:after {
                right: 1px;
              }
            }
          }
        }

        &.social-media {
          display: inline-block;
          background: rgba(255, 255, 255, 0.79);
          color: rgb(41,10,89);
          border-radius: 50%;
          width: 60px;
          height: 60px;
          margin-left: 10px;
          margin-right: 10px;
          text-align: center;
          line-height: 60px;
          font-size: 26px;

          @include media-breakpoint-down(sm) {
            width: 40px;
            height: 40px;
            line-height: 40px;
            margin-left: 8px;
            margin-right: 8px;
            font-size: 18px;
          }

          i {
            letter-spacing: 0;
          }

          &:hover, &:focus, &:active {
            background: #fff;
          }
        }
      }
    }
  }

  #menu-title {
    position: absolute;
    display: inline-block;
    left: 0;
    right: 0;
    font-family: "Playfair Display";
    font-weight: bold;
    font-size: 309px;
    letter-spacing: 0.2em;
    color: rgba(255,255,255,0.03);

    @include media-breakpoint-down(lg) {
      font-size: 260px;
    }

    @include media-breakpoint-down(md) {
      font-size: 210px;
    }

    @include media-breakpoint-down(sm) {
      font-size: 130px;
    }
  }

  #close {
    position: absolute;
    top: 28px;
    left: 15px;
    color: #fff;
    font-size: 12px;
    letter-spacing: 0.8em;
    z-index: 2;

    &:hover {
      text-decoration: none;
    }

    img {
      width: 31px;
      height: 31px;
      margin-right: 8px;
    }

    span {
      display: none;
    }

    @include media-breakpoint-up(md) {
      left: 25px;

      span {
        display: inline-block;
      }
    }

    @include media-breakpoint-up(lg) {
      top: 59.5px;
      left: 50px;
    }

  }
}