/*---------------------------------------*\
		Réglages globaux
\*---------------------------------------*/

@import "../base/variables";
@import "../tools/mixins";
@import "../../../../../../../node_modules/bootstrap/scss/mixins/breakpoints";

// Add font
@include font-face("Nimbus Sans", $font-dir+"/NimbusSans/nimbus_sans_light", 300, normal, otf);
@include font-face("Nimbus Sans", $font-dir+"/NimbusSans/nimbus_sans_regular", 500, normal, ttf);
@include font-face("Nimbus Sans", $font-dir+"/NimbusSans/nimbus_sans_bold", bold, normal, otf);
@include font-face("Nimbus Sans L", $font-dir+"/NimbusSansL/nimbus_sans_l_bold", bold, normal, otf);

@include font-face("Playfair Display", $font-dir+"/PlayfairDisplay/PlayfairDisplay-Regular", normal, normal, ttf);
@include font-face("Playfair Display", $font-dir+"/PlayfairDisplay/PlayfairDisplay-Bold", bold, normal, ttf);
@include font-face("Playfair Display", $font-dir+"/PlayfairDisplay/PlayfairDisplay-Black", 900, normal, ttf);


@include font-face("League Spartan", $font-dir+"/LeagueSpartan/LeagueSpartan-Bold", bold, normal, ttf otf woff);

*,
*::after,
*::before {
	position: relative;
	outline: none;
	-webkit-tap-highlight-color: none;
}

html {
	// Media queries
	/*
	@media #{md("s")} {
		font-size: $font-size - 1;
	}

	@media #{md("m")} {
		font-size: $font-size;
	}

	@media #{md("xl")} {
		font-size: $font-size + 1;
	}

	@media #{md("xxl")} {
		font-size: $font-size + 2;
	}*/
}

// Better font rendering for mac
.is-mac,
.is-mac input,
.is-mac button,
.is-mac textarea {
	@include antialiased;
}

/*------------------------------------*\
		Base typographique

		More settings:
		http://type-scale.com/
\*------------------------------------*/

main {
	padding-top: 160px;
	overflow-x: hidden;

	@include media-breakpoint-down(sm) {
		padding-top: 100px;
	}
}

body {
	font-size: $font-size;
	font-family: "Nimbus Sans";
	line-height: $line-height;
	overflow-x: hidden;
	letter-spacing: 0.01em;

	// Helper to get current breakpoint in JS
	&::before {
		z-index: z("limbo");
		position: absolute;
		width: 1px;
		height: 1px;
		white-space: nowrap;
		overflow: hidden;
		clip: rect(1px, 1px, 1px, 1px);
	}

	i {
		letter-spacing: 0;
	}
}

p,
ul,
ol,
blockquote {
	margin-bottom: $line-height * 1em;
}

h1,
h2,
h3,
h4 {
	margin-top: 0;
	margin-bottom: 0;
	line-height: 1.2;
	font-weight: 700;
}

h1 {
	margin-top: 0;
	font-size: 3.157em;
}

h2 {
	font-size: 2.369em;
}

h3 {
	font-size: 1.777em;
}

h4 {
	font-size: 1.333em;
}

small {
	font-size: 0.75em;
}

sup {
	vertical-align: super;
	font-size: 0.75em;
}

a {
	color: inherit;
}

p {
	margin-bottom: 0;
}


ul,
ol {
	margin-left: 1em;
	padding-left: 1em;
}

b,
strong {
	font-weight: bold !important;
}

.caldera-grid {
	.row {
		margin-bottom: 40px;

		@include media-breakpoint-down(xl) {
			margin-bottom: 20px;
		}

		&.last_row {
			margin-top: -25px;
			margin-bottom: 0;
		}

		.form-group {
			.control-label {
				margin-bottom: 0;
				color: #686868;
				letter-spacing: 0.2em;

				.field_required {
					display: none;
				}
			}

			.form-control {
				height: 28px;
				border-radius: 0;
				border: none;
				border-bottom: 1px solid #090909;
				background: transparent;
				box-shadow: none;
				outline: 0;
				color: $black;
				font-weight: bold;
				font-size: 15px;
				padding-top: 3px;
				padding-bottom: 0px;

				&:not([type=tel]) {
					padding-left: 0;
					padding-right: 0;
				}
			}

			textarea {
				height: 90px !important;
			}
		}

		.intl-tel-input {
			display: block;
		}

		.checkbox {
			margin-top: 0;
			margin-bottom: 0;

			label {
				padding-left: 41px;
				line-height: 16px;
				font-size: 12px;
				color: #090909;

				.checkbox-img {
					display: inline-block;
					content: " ";
					width: 25px;
					height: 23px;
					top: 12px;
					margin-left: -40px;
					margin-right: 10px;
					margin-top: 10px;
					background-image: url($svg-dir + 'checkbox.svg');
					background-size: contain;
					background-repeat: no-repeat;
				}

				input[type=checkbox] {
					display: none;

					&:checked + .checkbox-img:after {
						position: absolute;
						left: 7px;
						content: "✓";
						display: inline-block;
						color: $black;
					}
				}
			}
		}
	}
}

#contact-section {
	#call-back-form {
		display: none;
		margin-top: 40px;

		#send-call-back {
			margin-top: 20px;
		}
	}

	#contact-form {
		@include media-breakpoint-down(md) {
			margin-top: 40px;
		}
	}
}